<template>
    <v-container>
        <v-row>
            <v-card outlined width="100%">
                <v-row style="margin:auto">
                    <v-col cols="3">
                        <s-text label="Iniciales Codigo" v-model="item.Iniciales" ></s-text>
                    </v-col>

                    <v-col cols="3">
                        <s-text label="Longitud Codigo" number v-model="item.longitud" :min="0"></s-text>
                    </v-col>

                    <v-col cols="3">
                        <s-text label="Cantidad a Generar" number v-model="item.quantity" :min="0"></s-text>
                    </v-col>

                    <v-col cols="3">
                        <s-text label="Correlativo Inicio" number v-model="item.NumberInitial" :min="0"></s-text>
                    </v-col>
                </v-row>
                <v-row style="margin:auto">
                    <v-col cols="6">
                        <v-btn @click="printLabel()" small color="success" elevation="0">Generar</v-btn>
                        <v-btn class="ml-4" @click="printQR()" small color="info" elevation="0">Imprimir</v-btn>
                    </v-col>

                     <v-col cols="12">
                        <label v-for="item in itemLabel" :key="item.CodeActive">
                            {{item.CodeActive}}<br>
                        </label>
                    </v-col>
                     <!-- <v-col cols="2">
                        <v-btn @click="printQR()" small color="info" elevation="0">Imprimir</v-btn>
                    </v-col> -->
                    <v-col cols="12" v-for="item in itemLabel" :key="item.CodeActive" style="display:none">
                        <qr-code :size="50" :text="item.CodeActive" error-level="H">
                        </qr-code>
                    </v-col>
                    <!-- https://imperu.com.pe/files/MCC_MirafloresCountryClub.kmz 
                    
                    https://imperu.com.pe/files/BPP_UsosyManzanas.kmz-->

                    <!-- <v-col cols="12" v-for="item in itemLabel" :key="item.CodeActive" style="display:none"> -->
                       <!--  <qr-code :size="250" :text="'https://carreraimp10k.imperu.com.pe/'" error-level="H">
                        </qr-code> -->
                    <!-- </v-col> -->
                   
                </v-row>
            </v-card>

            
        </v-row>
    </v-container>
</template>

<script>
import qrCode from "vue-qr-generator";

import Barcode from 'vue-barcode';

export default {
    components: {
        qrCode,
        Barcode
    },
    data: () => ({
        itemLabel: [],
        item: {
            NumberInitial: 100,
            quantity: 50,
            longitud:10,
            Iniciales: 'SIS'
        }
    }),
    created() {

    },
    methods: {

        printLabel() {
				this.itemLabel = [];
				let initital = parseInt(this.item.NumberInitial);
				let end = parseInt(this.item.quantity) + initital
                var  j = 0

                var codigo = this.item.Iniciales
                var restante = this.item.longitud - codigo.length
                var newRestante, newCadena;

                var formato = "00000000000000000000000000000000000000";

        
				for (var i = initital; i < end; i++) {

                    if(i < 10){
                        newRestante = restante - 1
                        newCadena = formato.substring(0, newRestante)
                        codigo = this.item.Iniciales + newCadena + i;
                    }else if(i < 100){
                        newRestante = restante - 2
                        newCadena = formato.substring(0, newRestante)
                        codigo = this.item.Iniciales + newCadena + i;
                    }else if(i < 1000){
                        newRestante = restante - 3
                        newCadena = formato.substring(0, newRestante)
                        codigo = this.item.Iniciales + newCadena + i;
                    }else if(i < 10000){
                        newRestante = restante - 4
                        newCadena = formato.substring(0, newRestante)
                        codigo = this.item.Iniciales + newCadena + i;
                    }else if(i < 10000){
                        newRestante = restante - 5
                        newCadena = formato.substring(0, newRestante)
                        codigo = this.item.Iniciales + newCadena + i;
                    }

					let obj = { 
                        xNumber: i, 
                        CodeActive: codigo, 
                        Position: j 
                    };
					this.itemLabel.push(obj);
                    j = j + 1 

                    console.log(codigo, restante, newRestante, newCadena)
				}

                console.log(this.itemLabel)
                
			},
      
        printQR() {

                var items = [
                    {descripcion: "TRACTOR GIRO ZERO", modelo:"Z254", numeroserie:"073118C016872"},
                    {descripcion: "CORTACESPED", modelo:"LC356", numeroserie:"20195000055"},
                    {descripcion: "CORTACESPED", modelo:"LC356", numeroserie:"20195000060"},
                    {descripcion: "CORTACESPED", modelo:"LC356", numeroserie:"20195000061"},
                    {descripcion: "DESBROZADORA", modelo:"545FR", numeroserie:"20193300084"},
                    {descripcion: "DESBROZADORA", modelo:"545FR", numeroserie:"20193300087"},
                    {descripcion: "PODADORA DE ALTURA", modelo:"525PT5S", numeroserie:"20193900091"},
                    {descripcion: "PODADORA DE ALTURA", modelo:"525PT5S", numeroserie:"20193900068"},
                    {descripcion: "PODADORA DE ALTURA", modelo:"525PT55", numeroserie:"20193900077"},
                    {descripcion: "MOTOSIERRA", modelo:"395XP", numeroserie:"20193835642"},
                    {descripcion: "CORTASETOS", modelo:"325HEA", numeroserie:"20184100019"},
                    {descripcion: "CORTASETOS", modelo:"325HEA", numeroserie:"20192500041"},
                    {descripcion: "FUMIGADORA", modelo:"423", numeroserie:"O984101"},
                    {descripcion: "PODADORA", modelo:"520", numeroserie:"NO SE RECONOCE"},
                    {descripcion: "PODADORA", modelo:"520", numeroserie:"NO SE RECONOCE"},
                ]

                var numero = [
                    {descripcion: "01",},
                    {descripcion: "02",},
                    {descripcion: "03",},
                    {descripcion: "04",},
                    {descripcion: "05",},
                    {descripcion: "06",},
                    {descripcion: "07",},
                    {descripcion: "08",},
                    {descripcion: "09",},
                    {descripcion: "10",},
                    {descripcion: "11",},
                    {descripcion: "12",},
                    {descripcion: "13",},
                    {descripcion: "14",},
                    {descripcion: "15",},
                ]
                    
                

				var imgAll = document.querySelectorAll("img");
                
				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 10);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style media='print'>  @media print  { @page { margin-top: 0cm; {} </style></head><body onload='step1()'></body></html>",
				);

                /* items.forEach((element, index) => {
                    console.log("element", element)
                        pwa.document.write("<table style='margin-bottom: 1500px; margin-left: 50px; margin-top: 10px' border='0'><tr><td colspan='2' style='text-align: center; font-size: 9px; font-family: Calibri'><b></b></td></tr>")
                        pwa.document.write(
                            "<tr><td></td><td style='text-align: left'><div style='margin: 0px 0px 0px 0px;'>"+
                            element.descripcion+ "<br>" +
                            "Modelo: " +element.modelo+ "<br>" +
                            "Nro. Serie: " +element.numeroserie+
                        "</div></td></tr>",
                        );
                        pwa.document.write("<tr><td></td><td style='text-align: center; font-size: 10px; margin-top:150px'><b></b></td></tr>")
                        pwa.document.write("</table>")
                }); */

                /* numero.forEach((element, index) => {
                    console.log("element", element)
                        pwa.document.write("<table style='margin-bottom: 1900px; margin-left: 90px; margin-top: -20px' border='0'><tr><td colspan='4' style='text-align: center; font-size: 54px; font-family: Calibri'><b></b></td></tr>")
                        pwa.document.write(
                            "<tr><td></td><td style='text-align: center;font-size: 54px;font-weight: 700;'><div style='margin: 20px 20px 20px 20px;font-size:54px'>"+
                            element.descripcion+ "<br>" +
                        "</div></td></tr>",
                        );
                        pwa.document.write("<tr><td></td><td style='text-align: center; font-size: 54px;font-weight: 700; margin-top:0px'><b></b></td></tr>")
                        pwa.document.write("</table>")
                }); */

                /*  pwa.document.write("<table style='margin-bottom: 800px; margin-left: 90px; margin-top: -12px' border='0'><tr><td colspan='2' style='text-align: center; font-size: 9px; font-family: Calibri'><b>ACTIVOS 06/2023 </b></td></tr>")
                        pwa.document.write(
                            "<tr><td></td><td style='text-align: center'><div style='margin: 0px 0px 0px 0px;'><img alt='Scan me!' style='display: block;'  src='" +
                                "dddddddddddddd" +
                                "' /></div></td></tr>",
                        );
                        pwa.document.write("<tr><td></td><td style='text-align: center; font-size: 10px; margin-top:-50px'><b>" + "Holaaa" + "</b></td></tr>")
                        pwa.document.write("</table>") */

                    imgAll.forEach((element, index) => {
                        console.log("index", index)
                        pwa.document.write("<table style='margin-bottom: 800px; margin-left: 90px; margin-top: -12px' border='0'><tr><td colspan='2' style='text-align: center; font-size: 9px; font-family: Calibri'><b>INVENTARIO 09/2023 </b></td></tr>")
                        pwa.document.write(
                            "<tr><td><img height='50px' width='100px' alt='Scan me!' style='display: block;'  src='" +
                                'https://imperu.com.pe/Logos/Imp_inventario.png' +
                                "' /></td><td style='text-align: center'><div style='margin: 0px 0px 0px 0px;'><img alt='Scan me!' style='display: block;'  src='" +
                                element.currentSrc +
                                "' /></div></td></tr>",
                        );
                        pwa.document.write("<tr><td></td><td style='text-align: center; font-size: 10px; margin-top:-50px'><b>" + this.itemLabel.filter(x => x.Position == index)[0].CodeActive + "</b></td></tr>")
                        pwa.document.write("</table>")
                    });

                pwa.document.write("")		        
				pwa.document.close();

				
			},
    },

};
</script>
